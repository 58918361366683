@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: rgb(5, 109, 128);
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("https://www.instagram.com/p/CBge8l3H-ce/");
  height: 30vh;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

h1 {
  font-weight: 400;
}

input {
  padding: 5px;
}
input:focus {
  border: 2px solid rgb(5, 109, 128);
  outline: none;
  border-radius: 5px;
}

.button {
  padding: 5px;
  background-color: whitesmoke;
  border-radius: 5px;
}

.button:hover {
  text-decoration: none;
  color: whitesmoke;
  background-color: rgb(147, 208, 215);
  transition: 500ms;
}

.book-card {
  background-color: whitesmoke;
  padding: 15px;
  border-radius: 10px;
  /* min-height: 300px; */
  margin-bottom: 15px;
}

.price {
  font-size: 1.3rem;
  font-weight: 200;
  border: 1px dotted rgb(5, 109, 128);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.price p {
  margin-top: 15px;
}

.cd-wrapper {
  width: 200px;
  height: 170px;
  overflow: hidden;
  margin: 4px;
}

a {
  color: rgb(5, 109, 128);
}

iframe {
  max-width: 100%;
}

.contact-social a {
  margin: 7px;
}

.logo:hover {
  filter: saturate(2);
}

.contact-middle {
  background-color: rgb(147, 208, 215);
  /* height: 70px; */
}

.contact-card {
  background-color: white;
  color: rgb(147, 208, 215);
  text-align: center;
  border-radius: 25px;
  margin: 10px auto;
  padding: 5px;
  width: 80%;
}

.contact-middle a {
  color: rgb(5, 109, 128);
}

.contact-middle a:hover {
  text-decoration: none;
  color: rgb(191, 174, 130);
}

.video-container:hover {
  filter: saturate(150%);
}

.content {
  padding: 3%;
  min-height: 50vh;
}

.page-contents {
  animation: 1s fadeIn ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.flags {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag {
  color: whitesmoke;
  margin: 0 4px;
}

.flag:hover {
  cursor: pointer;
  color: rgb(5, 109, 128);
}

.form {
  display: flex;
  flex-direction: column;

  margin: 25px 0;
  background-color: white;
  padding: 20px;
}

label {
  color: black;
}

.form input {
  margin-bottom: 20px;
  outline: none;
}

.home-project-list {
  margin-top: 25px;
}

.home-column {
  width: 80%;
  margin: 30px auto 0;
}

.home-column-post {
  padding: 5px;
  padding-bottom: 10px;
  background-color: rgb(230, 227, 227);
  margin: 10px auto;
}

.home-column-post img {
  margin-bottom: 5px;
}

.main-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.hero {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("https://www.instagram.com/p/CBge8l3H-ce/");
  height: 30vh;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

#hero-subtitle {
  font-weight: 100;
}

.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px;
  color: rgb(49, 198, 218);
}

.middle {
  padding: 5%;
  background-color: rgba(0, 0, 0, 0.3);
}

.middle-text {
  padding: 30px;
}
.feature {
  filter: saturate(0.5);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(103, 121, 14);
  margin: 60px;
  text-align: center;
}

.feature:hover {
  filter: saturate(1.5);

  cursor: pointer;
  color: rgb(49, 198, 218);
  text-decoration: none;
}

.feature img:hover {
  transform: rotate(90deg);
  transition: 800ms;
}

.photo-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.photo-container img {
  max-width: 100%;
  height: 300px;
}

.link:hover {
  text-decoration: none;
}

.privacy-link {
  margin-bottom: 25px;
  color: darkgreen;
}

.privacy-button {
  margin-left: 20px;
}

.close-cross:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.privacy-link:hover {
  cursor: pointer;
}

.privacy-text {
  text-align: justify;
  margin-top: 25px;
}

.privacy-text a {
  color: black;
}

.recording-images {
  display: flex;
  margin-top: 10px;
  margin-left: 5px;
}
.recording-images a {
  margin-right: 5px;
}

.short-post {
  background-color: whitesmoke;
  padding: 15px;
}

.short-post img {
  border-radius: 0 0 9px 9px;
  margin-bottom: 5px;
}

.small-button {
  border: 0.5px solid rgb(119, 54, 4);
  border-radius: 15px;
  padding: 10px;
  color: rgb(173, 78, 6);
  margin: 4% 0 0 0;
}

.xsmall-button {
  border-radius: 5px;
  padding: 5px;
  color: black !important;
  background-color: white;
  margin-bottom: 4%;
  outline: none;
  border: 1px solid gray;
}

.small-button:hover,
.xsmall-button:hover {
  background-color: rgb(191, 174, 130);
  color: gray;
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
}

.single-cd {
  display: flex;
  flex-direction: column;
}

.single-cd-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.single-cd-links a {
  color: black;
}

.audio-file {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 9px;
  margin-bottom: 3px;
}

.text-box {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 13px;
}

.text-box a {
  color: rgb(235, 210, 192);
}

.text-box-light {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  padding: 13px;
}

.big-button {
  color: rgb(119, 54, 4);
  padding: 2%;
  background-color: rgb(119, 54, 4);
  border-radius: 10px;
  background-color: rgb(240, 226, 206);
}

.big-button:hover {
  background-color: rgb(163, 114, 76);
  color: rgb(119, 54, 4);
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
}

.nav-right {
  display: flex;
  align-items: center;
}

.nav-right a {
  margin: 7px;
}

.nav-link {
  padding: 0px;
}

#social {
  margin-bottom: 20px;
  text-align: right;
}

.title-bar {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title-bar h3 {
  font-weight: 300;
  font-size: 3rem;
}
.sharing-buttons {
  text-align: right;
}

#itunes {
  color: steelblue;
}

#itunes:hover {
  color: rgb(98, 179, 245);
}

#spotify {
  color: green;
}

#spotify:hover {
  color: rgb(5, 216, 5);
}

/* LIGHTBOX ETC */
/* image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 50%;
  min-height: 50%;
  max-width: 150%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto 20px;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

.event {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  margin-bottom: 2%;
  margin-top: 2%;
  padding: 5%;
  min-height: 300px;
}

.event img {
  margin-right: -60px;
  right: 0;
  width: 100%;
}

.recording a {
  color: rgb(235, 210, 192);
}

.project {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 1%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project img {
  height: 40vh;
  max-width: auto;
}

.project a {
  color: rgb(119, 54, 4);
}

.news-item {
  background-color: rgba(255, 255, 255, 0.851);
  color: rgb(43, 115, 174);
  margin-bottom: 5%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news-item h5 {
  color: rgb(5, 40, 78);
  font-weight: 300;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/*image overlays*/

.main-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* float: left; */
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay img {
  width: 70%;
  margin: auto;
}
.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #05050598;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}
.main-box:hover .overlay {
  opacity: 0.9;
}
