.toolbar{
    position: fixed;
    background-color: rgb(147, 208, 215);
    color: grey;
    width: 100%;
    height: 156px;
    top: 0;
    left: 0;
    z-index: 200;
  
    
}

.toolbar img{
    height: 156px;
}

.toolbar__navigation{
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo{
    margin-left: 1rem;
}
.toolbar__logo a{
color: grey;
text-decoration: none;
font-size: 1.5rem;
}

.spacer{
    flex: 1;
}
.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 .5rem ;
}

.toolbar_navigation-items a {
    color: gray;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: darkgrey;
}

